<template>
	<div id="pagepages">
		<div class="page_navigator">
			<PageTools />
		</div>

		<div id="lespages" v-if="$store.state.ordre_des_pages && $store.state.offre_ready && $store.state.offre_active.statut == 'draft'">
			<Page1 :class="$store.state.ordre_des_pages.page1.visible ? 'page' : 'masked page'" :style="'order: ' + $store.state.ordre_des_pages.page1.ordre" />
			<Page2 :class="$store.state.ordre_des_pages.page2.visible ? 'page' : 'masked page'" :style="'order: ' + $store.state.ordre_des_pages.page2.ordre" />
			<Page3 :class="$store.state.ordre_des_pages.page3.visible ? 'page' : 'masked page'" :style="'order: ' + $store.state.ordre_des_pages.page3.ordre" />
			<Page4 :class="$store.state.ordre_des_pages.page4.visible ? 'page' : 'masked page'" :style="'order: ' + $store.state.ordre_des_pages.page4.ordre" />
			<Page5 :class="$store.state.ordre_des_pages.page5.visible ? 'page' : 'masked page'" :style="'order: ' + $store.state.ordre_des_pages.page5.ordre" />
			<Page6 :class="$store.state.ordre_des_pages.page6.visible ? 'page' : 'masked page'" :style="'order: ' + $store.state.ordre_des_pages.page6.ordre" />
			<Page7 :class="$store.state.ordre_des_pages.page7.visible ? 'page' : 'masked page'" :style="'order: ' + $store.state.ordre_des_pages.page7.ordre" />
			<Page8 :class="$store.state.ordre_des_pages.page8.visible ? 'page' : 'masked page'" :style="'order: ' + $store.state.ordre_des_pages.page8.ordre" />
			<Page9 :class="$store.state.ordre_des_pages.page9.visible ? 'page' : 'masked page'" :style="'order: ' + $store.state.ordre_des_pages.page9.ordre" />
			<Page10 :class="$store.state.ordre_des_pages.page10.visible ? 'page' : 'masked page'" :style="'order: ' + $store.state.ordre_des_pages.page10.ordre" />
			<Page11 :class="$store.state.ordre_des_pages.page11.visible ? 'page' : 'masked page'" :style="'order: ' + $store.state.ordre_des_pages.page11.ordre" />
			<Page12 :class="$store.state.ordre_des_pages.page12.visible ? 'page' : 'masked page'" :style="'order: ' + $store.state.ordre_des_pages.page12.ordre" />
		</div>
		<div v-else class="pdf-download">
			<a :href="$store.state.offre_active.url_pdf_envoye" download target="_blank">
				<button>Télécharger le PDF</button>
			</a>
		</div>
	</div>
</template>
<script>
import Airtable from "airtable";
const base = new Airtable({ apiKey: "patbf88vmcP5sIIit.ee71112a4ec13dfd6d6e7072aedf4fe93d4a1a67c5f56495c75a4c178ef61a90" }).base("appYI0r0gqwXitAoC");
import Page1 from "../components/pages/page1";
import Page2 from "../components/pages/page2";
import Page3 from "../components/pages/page3";
import Page4 from "../components/pages/page4";
import Page5 from "../components/pages/page5";
import Page6 from "../components/pages/page6";
import Page7 from "../components/pages/page7";
import Page8 from "../components/pages/page8";
import Page9 from "../components/pages/page9";
import Page10 from "../components/pages/page10";
import Page11 from "../components/pages/page11";
import Page12 from "../components/pages/page12";
import PageTools from "../components/pages/pages_tools";
export default {
	name: "Pages",
	components: {
		Page1,
		Page2,
		Page3,
		Page4,
		Page5,
		Page6,
		Page7,
		Page8,
		Page9,
		Page10,
		Page11,
		Page12,
		PageTools,
	},
	data() {
		return {};
	},
	watch: {},
	methods: {},
};
</script>

<style lang="less">
#pdf {
	right: 0;
	position: relative;
	bottom: 0;
	flex: 1;
	margin-left: 280px;
	min-height: calc(~"100vh - 80px");
}

.pdf-download {
	display: flex;
	align-items: flex-start;
	width: 100%;
	padding-top: 100px;
	padding-left: 280px;
	justify-content: center;
	button {
		background-color: #fff;
		appearance: none;
		cursor: pointer;
		padding: 10px 20px;
		border-radius: 4px;
		border: solid 1px rgba(black, 0.1);
		transition: all 0.2s ease-in-out;
		font-weight: 500;
		font-size: 15px;
		&:hover {
			box-shadow: 4px 4px 10px rgba(black, 0.1);
			transform: translateY(-3px);
			background: #312483;
			color: white;
		}
	}
}
#lespages {
	position: relative;
	padding: 50px 10px 50px 10px;
	flex: 1;
	margin-left: 280px;
	display: flex;
	flex-direction: column;

	.page {
		display: block;
		background-color: #fff;
		width: 1190px;
		height: 1684px;
		min-height: 1684px;
		margin-left: auto;
		margin-right: auto;
		border-radius: 4px;
		margin-bottom: 30px;
		padding: 50px;
		position: relative;
		transform-origin: center;
		box-shadow: unset !important;
		&.masked {
			display: none;
		}
		&.readyforexport {
			//transform: scale(0.5);
			&.masked {
				display: none;
			}
			.recap .masked {
				display: none !important;
			}
		}
	}
}
</style>
