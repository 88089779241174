<template>
	<li ref="puce" :data-value="item.masked ? '0' : item.prix" :class="item.masked ? 'option masked' : 'option'">
		<span contenteditable="true" v-if="$store.state.offre_active.langue == 'fr'" @blur="handleTextChange">
			{{ item.custom_text ? item.custom_text : item.fields.nom_fr }}
		</span>
		<span contenteditable="true" v-if="$store.state.offre_active.langue == 'de'" @blur="handleTextChange">
			{{ item.custom_text ? item.custom_text : item.fields.nom_de }}
		</span>
		<span contenteditable="true" v-if="$store.state.offre_active.langue == 'en'" @blur="handleTextChange">
			{{ item.custom_text ? item.custom_text : item.fields.nom_en }}
		</span>
		<span contenteditable="true" v-if="$store.state.offre_active.langue == 'pl'" @blur="handleTextChange">
			{{ item.custom_text ? item.custom_text : item.fields.nom_pl }}
		</span>
		<span contenteditable="true" v-if="$store.state.offre_active.langue == 'us'" @blur="handleTextChange">
			{{ item.custom_text ? item.custom_text : item.fields.nom_us }}
		</span>
		<span contenteditable="true" v-if="$store.state.offre_active.langue == 'ro'" @blur="handleTextChange">
			{{ item.custom_text ? item.custom_text : item.fields.nom_ro }}
		</span>
		<span class="price" v-if="!item.modifiable">
			<money-format :value="item.prix * taux_de_change" :currency-code="$store.state.offre_active.devise"
				:subunits-value="false" :hide-subunits="false" :locale="$store.state.offre_active.langue">
			</money-format>
			<!-- {{ item.prix }} € -->
		</span>
		<span class="price" v-if="item.modifiable && item.prix == 0 && item.masked == true"> - </span>
		<span class="price" v-if="item.modifiable && item.prix > 0 && item.masked == true">
			<money-format :value="item.prix * taux_de_change" :currency-code="$store.state.offre_active.devise"
				:subunits-value="false" :hide-subunits="false" :locale="$store.state.offre_active.langue">
			</money-format>
			<!-- {{ item.prix }} €  -->
		</span>
		<span class="price" v-if="item.modifiable && item.masked == false">
			<money-format :value="item.prix * taux_de_change" :currency-code="$store.state.offre_active.devise"
				:subunits-value="false" :hide-subunits="false" :locale="$store.state.offre_active.langue">
			</money-format>
			<div class="changevalue"><input type="text" v-model="item.prix" @blur="handleInputChange" /> €</div>
		</span>
		<span class="activate">
			<i v-if="item.masked" class="fal fa-eye" @click="handleAction(false)"></i>
			<i v-else class="fal fa-eye-slash" @click="handleAction(true)"></i>
		</span>
	</li>
</template>
<script>
import MoneyFormat from "vue-money-format";

export default {
	name: "Puce",
	props: ["item"],
	components: { MoneyFormat },
	data() {
		return {
			taux_de_change: 1,
		};
	},
	methods: {
		handleTextChange(e) {
			this.$emit('update-item-custom-text', {
				id: this.item.id,
				value: e.target.innerText,
			});
		},
		handleInputChange(e) {
			this.$refs.puce.dataset.value = e.target.value;
			this.$emit('update-item-price', {
				id: this.item.id,
				value: this.item.prix,
			});
			setTimeout(() => {
				this.$emit("totalChange");
			}, 100);
		},
		handleAction(bool) {
			this.$emit('update-item-visibility', {
				id: this.item.id,
				value: bool,
			});
			
		},
	},
	mounted() {
		if (this.$store.state.offre_active.devise != "EUR") {
			if (this.$store.state.offre_active.taux_de_change_perso) {
				this.taux_de_change = this.$store.state.offre_active.taux_de_change_perso;
			} else {
				this.taux_de_change = this.$store.state.currencies[this.$store.state.offre_active.devise];
			}
		}
	},
};
</script>

<style lang="less">
li.option {
	display: flex;
	list-style: decimal inside;
	justify-content: space-between;
	padding: 5px 0;
	border-radius: 6px;
	transition: all 0.3s ease;
	cursor: pointer;
	position: relative;
	.activate {
		display: none;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
	&:hover {
		padding-right: 30px !important;
		.activate {
			display: block;
			
		}
	}
	&.masked {
		span,
		input {
			color: lighten(black, 80%) !important;
		}
	}
	b {
		color: #312483;
		font-weight: bold;
		flex: 0 0 auto;
		width: 40px;
	}
	span {
		flex: 1;
		text-align: left;
	}
	span.price {
		flex: 0 0 auto;
		width: 100px;
		text-align: right;
		text-transform: uppercase;
		font-size: 0.8em;
		font-weight: bold;
		color: #312483;
		.money_format {
			white-space: nowrap;
		}
		.changevalue {
			display: none;
			input[type="text"] {
				border: none;
				appearance: none;
				width: 50px;
				color: #312483;
				font-size: 0.9em;
				font-weight: bold;
				text-align: right;
				outline: none;
				border-bottom: solid 1px fadeOut(black, 90%);
			}
		}
		&:hover {
			.money_format {
				display: none;
				
			}
			.changevalue {
				display: block;
			}
		}
	}
	span.included {
		text-align: right;
		color: #312483;
		text-transform: uppercase;
		font-size: 0.9em;
		font-weight: bold;
	}
}
.vue-tooltip-theme {
	background: fadeOut(black, 10%);
	font-size: 12px;
	padding: 5px;
	color: white;
	font-weight: 500;
}
</style>
