<template>
	<div id="pageoffre">
		
		<Header />
		<main v-if="$store.state.offre_active">
			<Pages />
		</main>
	</div>
</template>
<script>
import Header from "../components/Header";
import Pages from "../components/Pages";
export default {
	name: "Offre",
	components: { Header, Pages },
	data() {
		return {
			
		};
	},
	mounted() {
		this.$store.dispatch("activeOffre", this.$route.params.id);
	},
	methods: {},
};
</script>

<style lang="less">
#pageoffre {
	main {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		#pagepages {
			flex: 1;
			order: 1;
			height: 100vh;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			display: flex;
			flex-direction: row;
			.page_navigator {
				flex: 0 0 auto;
				width: 280px;
				position: fixed;
				height: calc(100vh - 80px);
				overflow-y: auto;
				background: white;
				border-right: solid 1px rgba(black, 0.1);
			}
		}
		#toolbar {
			width: 400px;
			height: 100vh;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			background: white;
			border-left: solid 1px fadeOut(black, 94%);
			flex: 0 0 auto;
			order: 2;
			padding: 50px 30px;
		}
	}
}
</style>
