<template>
	<div id="page3" ref="page3">
		<div class="layout">
			<div class="visuel_pleine_page" :style="'background-image: url(' + page3_img + ')'">
				<div class="grostitre">
					<div v-if="$store.state.offre_active.page2_intitule && $store.state.offre_active.page2_intitule != '-'"
						class="machine" v-html="$store.state.offre_active.page2_intitule" />
					<div v-else class="machine">
						<span v-if="$store.state.offre_active.langue == 'fr'">{{
							$store.state.offre_active.machine.display_name_fr }}</span>
						<span v-if="$store.state.offre_active.langue == 'en'">{{
							$store.state.offre_active.machine.display_name_en }}</span>
						<span v-if="$store.state.offre_active.langue == 'de'">{{
							$store.state.offre_active.machine.display_name_de }}</span>
						<span v-if="$store.state.offre_active.langue == 'pl'">{{
							$store.state.offre_active.machine.display_name_pl }}</span>
						<span v-if="$store.state.offre_active.langue == 'us'">{{
							$store.state.offre_active.machine.display_name_us }}</span>
						<span v-if="$store.state.offre_active.langue == 'ro'">{{
							$store.state.offre_active.machine.display_name_ro }}</span>
						{{ $store.state.offre_active.machine.name }}
					</div>
				</div>
				<div class="filigrane">
					<span v-if="$store.state.offre_active.langue == 'fr'">(Photo non contractuelle)</span>
					<span v-if="$store.state.offre_active.langue == 'en'">(Non contractual picture)</span>
					<span v-if="$store.state.offre_active.langue == 'de'">(Nicht vertragliche Photo)</span>
					<span v-if="$store.state.offre_active.langue == 'pl'">(Zdjęcie nieumowne)</span>
					<span v-if="$store.state.offre_active.langue == 'us'">(Non contractual picture)</span>
					<span v-if="$store.state.offre_active.langue == 'ro'">(Poza necontractuala)</span>
				</div>
				<div class="form" name="image_form" id="image_form" @submit.prevent="">
					<input type="file" name="page3_image" id="page3_image" ref="page3_image"
						@change="handleFile" />
					<label for="page3_image">Click here <br />to select a new picture <br /><em>Format
							JPEG<br />Taille : 1090px * 1513px</em><i
							class="fad fa-image-polaroid"></i></label>
					<button type="submit">Submit</button>
				</div>
			</div>
			<div class="logo-pied-de-page">
				<img src="../../assets/logofooter.png" />
			</div>
			<PiedDePage :numero="$store.state.ordre_des_pages.page3.count" />
		</div>
	</div>
</template>
<script>
import moment from "moment";
import Airtable from "airtable";
import Axios from "axios";
var base = new Airtable({ apiKey: "patbf88vmcP5sIIit.ee71112a4ec13dfd6d6e7072aedf4fe93d4a1a67c5f56495c75a4c178ef61a90" }).base("appYI0r0gqwXitAoC");

export default {
	name: "page3",
	components: {},
	data() {
		return {
			page3_new: "",
		};
	},
	methods: {
		changePage3Image(val) {
			this.page3_new = val;
			this.$store.commit("setActiveElement", {
				field: "page3_image",
				value: val,
			});
		},
		handleChange(e) {
			if (this.$store.state.offre_active.statut != "draft") {
				alert("Sorry, you can't modify an issued offer.");
			} else {
				this.$store.commit("setFreeze", {
					affichage: true,
					texte: "Saving your data.<br /><b>Please Wait.</b>",
				});
				this.$store.state.offre_active[e.currentTarget.dataset.field] = e.currentTarget.innerHTML;
				let fields = {
					[e.currentTarget.dataset.field]: e.currentTarget.innerHTML,
				};
				base("offres").update(
					[
						{
							id: this.$store.state.offre_active.id,
							fields: fields,
						},
					],
					(err, records) => {
						this.$store.commit("setFreeze", {
							affichage: false,
							texte: "",
						});
					}
				);
			}
		},
		handleFile(e) {
			this.$store.commit("setFreeze", {
				affichage: true,
				texte: "Uploading your picture.<br /><b>Please wait.</b>",
			});
			const data = new FormData();
			data.append("service", "addmedia");
			data.append("file", e.target.files[0]);
			Axios.post("https://agents.easyflat-1892.com/api/custom_service/", data).then((res) => {
				var url = {
					url: res.data.result.lien,
				};
				base("offres").update(
					[
						{
							id: this.$route.params.id,
							fields: {
								page3_image: [
									{
										url: res.data.result.lien,
									},
								],
							},
						},
					],
					(err, records) => {
						if (err) {
							console.log(err);
							return;
						}

						records.forEach((record) => {
							this.$store.commit("setFreeze", {
								affichage: true,
								texte: "Preparing your picture.<br /><b>Please wait.</b>",
							});
							setTimeout(() => {
								base("offres").find(record.id, (err, record2) => {
									if (err) {
										console.error(err);
										return;
									}
									this.changePage3Image(record2.fields.page3_image[0].url);
									this.$store.commit("setFreeze", {
										affichage: false,
										texte: "",
									});
								});
							}, 8000);
						});
					}
				);
			});
		},
	},
	computed: {
		page3_img: {
			get() {
				if (this.page3_new != "") {
					return this.page3_new;
				} else if (this.$store.state.offre_active.page3_image) {
					return this.$store.state.offre_active.page3_image[0].thumbnails.full.url;
				} else if (this.$store.state.offre_active.machine.image_machine) {
					return this.$store.state.offre_active.machine.image_machine[0].thumbnails.full.url;
				} else {
					return "";
				}
			},
			set(newVal) {
				this.page3_new = newVal;
			},
		},
	},
	mounted() {},
};
</script>

<style lang="less">
#page3 .layout {
	display: flex;
	height: 100%;
	word-break: break-word;
	.visuel_pleine_page {
		background-position: center;
		background-size: contain;
		display: block;
		width: 100%;
		height: calc(~"100% - 70px");
		background-repeat: no-repeat;
		position: relative;
		.filigrane {
			position: absolute;
			bottom: 0;
			right: 0;
			font-weight: 500;
			color: rgba(black, 0.5);
			font-style: italic;
		}
		.grostitre {
			position: absolute;
			font-size: 60px;
			line-height: 62px;
			font-weight: 500;
			text-transform: none;
			top: 50px;
			left: 50px;
			color: #312483;
			.machine {
				display: flex;
				flex-direction: column;
				span {
					color: lighten(black, 53%);
					font-size: 0.7em;
				}
			}
		}
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0px;
			background: fadeOut(black, 50%);
			backdrop-filter: blur(10px);
			z-index: 2;
			display: none;
		}
		.form {
			position: absolute;
			z-index: 10;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: white;
			display: none;
			flex-direction: column;
			text-align: center;
			button {
				display: none;
			}
			input[type="file"] {
				visibility: hidden;
			}
			label {
				font-size: 14px;
				font-weight: bold;
				cursor: pointer;
				em {
					display: block;
					font-weight: normal;
					text-transform: none;
					font-size: 12px;
					margin: 4px 0;
				}
				i {
					font-size: 100px;
					display: block;
					margin-top: 15px;
				}
			}
		}
		&:hover {
			&:before {
				display: block;
				animation: appearFromLeft 0.2s ease-out backwards;
			}
			.form {
				display: flex;
				animation: fadeIn 0.3s ease-out backwards;
				animation-delay: 0.3s;
			}
		}
	}
}
</style>
