<template>
	<div id="page5" ref="page5">
		<div class="layout" v-if="option_ready">
			<div class="part first">
				<div class="gauche"></div>
				<div class="droite">
					<h1>
						<span>3/</span>
						{{ $store.state.textes.page5.title }}
						<!-- <sup>{{ $store.state.textes.page5.ht }}</sup> -->
					</h1>
					<h2 v-if="$store.state.offre_active.page2_intitule && $store.state.offre_active.page2_intitule != '-'"
						class="machine" v-html="$store.state.offre_active.page2_intitule" />
					<h2 v-else class="machine">
						<span v-if="$store.state.offre_active.langue == 'fr'">{{
							$store.state.offre_active.machine.display_name_fr }}</span>
						<span v-if="$store.state.offre_active.langue == 'en'">{{
							$store.state.offre_active.machine.display_name_en }}</span>
						<span v-if="$store.state.offre_active.langue == 'us'">{{
							$store.state.offre_active.machine.display_name_us }}</span>
						<span v-if="$store.state.offre_active.langue == 'ro'">{{
							$store.state.offre_active.machine.display_name_ro }}</span>
						<span v-if="$store.state.offre_active.langue == 'de'">{{
							$store.state.offre_active.machine.display_name_de }}</span>
						<span v-if="$store.state.offre_active.langue == 'pl'">{{
							$store.state.offre_active.machine.display_name_pl }}</span>
						{{ $store.state.offre_active.machine.name }}
					</h2>
				</div>
			</div>

			<div class="part">
				<div class="gauche">
					<h3>
						<div v-if="$store.state.offre_active.langue == 'fr'">
							{{ $store.state.offre_active.machine.display_name_fr }} {{
							$store.state.textes.page5.equipee }}
						</div>
						<div v-if="$store.state.offre_active.langue == 'en'">
							{{ $store.state.offre_active.machine.display_name_en }} {{
							$store.state.textes.page5.equipee }}
						</div>
						<div v-if="$store.state.offre_active.langue == 'us'">
							{{ $store.state.offre_active.machine.display_name_en }} {{
							$store.state.textes.page5.equipee }}
						</div>
						<div v-if="$store.state.offre_active.langue == 'ro'">
							{{ $store.state.offre_active.machine.display_name_en }} {{
							$store.state.textes.page5.equipee }}
						</div>
						<div v-if="$store.state.offre_active.langue == 'de'">
							{{ $store.state.offre_active.machine.display_name_de }} {{
							$store.state.textes.page5.equipee }}
						</div>
						<div v-if="$store.state.offre_active.langue == 'pl'">
							{{ $store.state.offre_active.machine.display_name_pl }} {{
							$store.state.textes.page5.equipee }}
						</div>
						<span>
							<money-format
								:value="$store.state.offre_active.machine.prix_distrib * taux_de_change"
								:currency-code="$store.state.offre_active.devise"
								:subunits-value="false" :hide-subunits="false"
								:locale="$store.state.offre_active.langue">
							</money-format>
							<!-- {{
							($store.state.offre_active.machine.prix_distrib * taux_de_change)
								| currency_USD)
							}}-->
						</span>
					</h3>
				</div>
				<div class="droite">
					<hr />
					<div class="normal_description" @blur="handleChange"
						v-if="$store.state.offre_active.page5_description" contenteditable="true"
						data-field="page5_description"
						v-html="$store.state.offre_active.page5_description" />
					<div contenteditable="true" @blur="handleChange" data-field="page5_description"
						class="normal_description"
						v-if="$store.state.offre_active.langue == 'fr' && !$store.state.offre_active.page5_description"
						v-html="$store.state.offre_active.machine.description_fr"></div>
					<div contenteditable="true" @blur="handleChange" data-field="page5_description"
						class="normal_description"
						v-if="$store.state.offre_active.langue == 'en' && !$store.state.offre_active.page5_description"
						v-html="$store.state.offre_active.machine.description_en"></div>
					<div contenteditable="true" @blur="handleChange" data-field="page5_description"
						class="normal_description"
						v-if="$store.state.offre_active.langue == 'us' && !$store.state.offre_active.page5_description"
						v-html="$store.state.offre_active.machine.description_us"></div>
					<div contenteditable="true" @blur="handleChange" data-field="page5_description"
						class="normal_description"
						v-if="$store.state.offre_active.langue == 'ro' && !$store.state.offre_active.page5_description"
						v-html="$store.state.offre_active.machine.description_ro"></div>
					<div contenteditable="true" @blur="handleChange" data-field="page5_description"
						class="normal_description"
						v-if="$store.state.offre_active.langue == 'de' && !$store.state.offre_active.page5_description"
						v-html="$store.state.offre_active.machine.description_de"></div>
					<div contenteditable="true" @blur="handleChange" data-field="page5_description"
						class="normal_description"
						v-if="$store.state.offre_active.langue == 'pl' && !$store.state.offre_active.page5_description"
						v-html="$store.state.offre_active.machine.description_pl"></div>
					<div class="mini_galerie">
						<div class="picture_1"
							v-if="$store.state.offre_active.machine.image_miniatures && $store.state.offre_active.machine.image_miniatures[0]">
							<div class="fix"
								:style="'background-image: url(' + $store.state.offre_active.machine.image_miniatures[0].url + ')'">
							</div>
						</div>
						<div class="picture_2"
							v-if="$store.state.offre_active.machine.image_miniatures && $store.state.offre_active.machine.image_miniatures[1]">
							<div class="fix"
								:style="'background-image: url(' + $store.state.offre_active.machine.image_miniatures[1].url + ')'">
							</div>
						</div>
						<div class="picture_3"
							v-if="$store.state.offre_active.machine.image_miniatures && $store.state.offre_active.machine.image_miniatures[2]">
							<div class="fix"
								:style="'background-image: url(' + $store.state.offre_active.machine.image_miniatures[2].url + ')'">
							</div>
						</div>
						<legend>{{ $store.state.textes.page5.standard_control }}</legend>
					</div>
				</div>
			</div>

			<div class="part">
				<div class="gauche">
					<h3>
						{{ $store.state.textes.page5.machine_options
						}}<span>
							<money-format :value="sous_total_machines * taux_de_change"
								:currency-code="$store.state.offre_active.devise"
								:subunits-value="false" :hide-subunits="false"
								:locale="$store.state.offre_active.langue">
							</money-format>
							<!-- {{ sous_total_machines | currency }}  -->
						</span>
					</h3>
				</div>
				<div class="droite">
					<hr />
					<ol>
						<Puce class="subtotal-machines" v-for="item in options.options_machines"
							:item="item" :key="`${item}-${item.id}-${item.masked}`"
							@update-item-visibility="handleItemVisibility"
							@update-item-custom-text="handleCustomTexte"
							@update-item-price="handleUpdatePrice" v-on:totalChange="makeTotal" />
					</ol>
				</div>
			</div>

			<div class="your-advantages"
				v-if="$store.state.offre_active.langue == 'en' && $store.state.easyRoll[0].page5_avantages_en">
				<img :src="$store.state.easyRoll[0].page5_avantages_en[0].url" />
			</div>
			<div class="your-advantages"
				v-if="$store.state.offre_active.langue == 'us' && $store.state.easyRoll[0].page5_avantages_en">
				<img :src="$store.state.easyRoll[0].page5_avantages_us[0].url" />
			</div>
			<div class="your-advantages"
				v-if="$store.state.offre_active.langue == 'ro' && $store.state.easyRoll[0].page5_avantages_en">
				<img :src="$store.state.easyRoll[0].page5_avantages_ro[0].url" />
			</div>
			<div class="your-advantages"
				v-if="$store.state.offre_active.langue == 'fr' && $store.state.easyRoll[0].page5_avantages_fr">
				<img :src="$store.state.easyRoll[0].page5_avantages_fr[0].url" />
			</div>
			<div class="your-advantages"
				v-if="$store.state.offre_active.langue == 'de' && $store.state.easyRoll[0].page5_avantages_de">
				<img :src="$store.state.easyRoll[0].page5_avantages_de[0].url" />
			</div>
			<div class="your-advantages"
				v-if="$store.state.offre_active.langue == 'pl' && $store.state.easyRoll[0].page5_avantages_pl">
				<img :src="$store.state.easyRoll[0].page5_avantages_pl[0].url" />
			</div>
			<div class="logo-pied-de-page">
				<img src="../../assets/logofooter.png" />
			</div>
			<PiedDePage :numero="$store.state.ordre_des_pages.page5.count" />
		</div>
	</div>
</template>
<script>
import moment from "moment";
import Airtable from "airtable";
import Puce from "../puce";
const base = new Airtable({ apiKey: "patbf88vmcP5sIIit.ee71112a4ec13dfd6d6e7072aedf4fe93d4a1a67c5f56495c75a4c178ef61a90" }).base("appYI0r0gqwXitAoC");
import MoneyFormat from "vue-money-format";
import { mapActions } from "vuex";
export default {
	name: "page5",
	components: { Puce, MoneyFormat },
	data() {
		return {
			sous_total_machines: 0,
			total: 0,
			options: {
				options_inclues: [],
				options_machines: [],
			},
			option_ready: false,
			taux_de_change: 1,
		};
	},
	methods: {
		handleUpdatePrice(payload) {
			const index = this.options.options_machines.findIndex(i => i.id === payload.id);
			if (index !== -1) {
				this.$set(this.options.options_machines, index, {
					...this.options.options_machines[index],
					prix: payload.value
				});
				setTimeout(() => {
					this.makeTotal();
				}, 100);
			}
		},
		handleCustomTexte(payload) {
			const index = this.options.options_machines.findIndex(i => i.id === payload.id);
			if (index !== -1) {
				this.$set(this.options.options_machines, index, {
					...this.options.options_machines[index],
					custom_text: payload.value
				});
				setTimeout(() => {
					this.makeTotal();
				}, 100);
				
			}
		},
		handleItemVisibility(payload) {
			const index = this.options.options_machines.findIndex(i => i.id === payload.id);
			if (index !== -1) {
				this.$set(this.options.options_machines, index, {
					...this.options.options_machines[index],
					masked: payload.value
				});
				setTimeout(() => {
					this.makeTotal();
					//window.location.reload();
				}, 100);
			}
		},
		handleUpdateKey(newValue) {
			this.monItem.key = newValue;
		},
		mergeObjects(array1, array2) {
			let mergedObjects = {};
			array1.forEach(obj => {
				mergedObjects[obj.id] = obj;
			});

			// Parcourir le second tableau et fusionner les objets
			array2.forEach(obj => {
				if (mergedObjects[obj.id]) {
					// Fusionner l'objet du array2 avec celui du array1 tout en conservant les propriétés non conflictuelles
					mergedObjects[obj.id] = { ...mergedObjects[obj.id], ...obj };
				} else {
					// Ajouter l'objet du array2 s'il n'existe pas dans array1
					mergedObjects[obj.id] = obj;
				}
			});

			// Convertir l'objet résultant en tableau
			let mergedArray = Object.values(mergedObjects);
			return mergedArray;
		},
		...mapActions(["updateOptionsMachines"]),
		handleChange(e) {
			if (this.$store.state.offre_active.statut != "draft") {
				alert("Sorry, you can't modify an issued offer.");
			} else {
				this.$store.commit("setFreeze", {
					affichage: true,
					texte: "Saving your data.<br /><b>Please Wait.</b>",
				});
				this.$store.state.offre_active[e.currentTarget.dataset.field] = e.currentTarget.innerHTML;
				let fields = {
					[e.currentTarget.dataset.field]: e.currentTarget.innerHTML,
				};
				base("offres").update(
					[
						{
							id: this.$store.state.offre_active.id,
							fields: fields,
						},
					],
					(err, records) => {
						this.$store.commit("setFreeze", {
							affichage: false,
							texte: "",
						});
					}
				);
			}
		},
		makeTotal() {
			//Je sauvegarde les options machines
			let temp = [];
			this.options.options_machines.map((item) => {
				temp.push({
					id: item.id,
					prix: item.prix,
					masked: item.masked,
					custom_text: item.custom_text,
				});
			});
			this.updateOptionsMachines(temp);

			// LES SOUS TOTAUX
			this.sous_total_machines = 0;
			// je recupere toutes les options machines non masquées et je les additionne
			this.options.options_machines.map((item) => {
				if (!item.masked) {
					this.sous_total_machines += parseInt(item.prix);
				}
			});

			// var sous_totaux_machines = document.querySelectorAll(".subtotal-machines");
			// Array.prototype.forEach.call(sous_totaux_machines, (item) => {
			// 	this.sous_total_machines += parseInt(item.dataset.value);
			// });

			this.total =
				this.sous_total_machines +
				this.$store.state.offre_active.sous_total_controls +
				this.$store.state.offre_active.sous_total_accessoires +
				parseInt(this.$store.state.offre_active.machine.prix_distrib);

			this.$store.commit("totaux_page_5", {
				a: this.sous_total_machines,
				b: this.total,
			});
		},
		getOptions() {
			console.log("Machine", this.$route.params.machine);
			var options_inclues = [];
			var options_machines =  [];
			//var options_commandes = [];
			//var options_accessoires = [];
			var all_options = this.$store.state.options;
			all_options.options_machines.map((item) => {
				if (item.fields[this.$route.params.machine] != "impossible") {
					if (item.fields[this.$route.params.machine] == "comprise") {
						item["prix"] = 0;
						options_inclues.push(item);
					} else {
						let prix_distrib = parseInt(item.fields[this.$route.params.machine]);
						let difference = Math.round(prix_distrib * this.$store.state.offre_active.gamme_de_prix_machine[0]);
						item["prix"] = prix_distrib + difference;
						item.masked = true;
						if (item.fields.reserve_picot) {
							item["modifiable"] = true;
						}
						if (this.$store.state.user.role.administrator) {
							options_machines.push(item);
						} else {
							if (!item.fields.reserve_picot) {
								options_machines.push(item);
							}
						}
					}
				}
			});
			console.log(all_options);
			this.options["options_inclues"] = options_inclues;
			this.options["options_machines"] = this.$store.state.offre_active.config_options_machine ? this.mergeObjects(options_machines, JSON.parse(this.$store.state.offre_active.config_options_machine)) : options_machines;
			this.option_ready = true;
			setTimeout(() => {
				this.makeTotal();
			}, 1000);
		},
	},
	mounted() {
		this.getOptions();
		if (this.$store.state.offre_active.devise != "EUR") {
			if (this.$store.state.offre_active.taux_de_change_perso) {
				this.taux_de_change = this.$store.state.offre_active.taux_de_change_perso;
			} else {
				this.taux_de_change = this.$store.state.currencies[this.$store.state.offre_active.devise];
			}
		}
	},
};
</script>

<style lang="less">
#page5 .layout {
	display: block;
	h1 {
		color: #312483;
		text-transform: uppercase;
		font-size: 30px;
		line-height: 34px;
		font-weight: bold;
		color: #312483;
		margin-bottom: 5px;
		span {
			color: black;
		}
		sup {
			color: rgba(black, 0.4);
			font-size: 14px;
			text-transform: none;
			letter-spacing: 0px;
		}
	}
	h2 {
		color: lighten(black, 60%);
		text-transform: uppercase;
		font-size: 30px;
		line-height: 34px;
		font-weight: bold;
		margin-bottom: 0px;
		margin-top: 0;
	}
	h3 {
		position: relative;
		font-weight: bold;
		color: black;
		font-size: 30px;
		display: flex;
		flex-direction: column;
		margin: 0;
		span {
			color: #312483;
		}
		&:before {
			height: 10px;
			width: 55px;
			background: #312483;
			border: none;
			content: "";
			margin-bottom: 8px;
		}
	}
	.part {
		display: flex;
		margin-bottom: 70px;
		&.first {
			margin-bottom: 120px;
		}
		.gauche {
			flex: 0 0 300px;
		}
		.droite {
			flex: 1;
			padding-left: 80px;
			hr {
				border: none;
				height: 2px;
				background: #312483;
				display: block;
				margin-bottom: 10px;
			}
			ol,
			ul {
				list-style: decimal inside;
				padding: 0;
				li {
					list-style: none;
					justify-content: space-between;
					padding: 5px 0;
					border-radius: 6px;
					position: relative;
					padding-left: 20px;
					&:before {
						content: "";
						position: absolute;
						left: 0;
						top: 14px;
						width: 10px;
						height: 3px;
						background: #312483;
					}
				}
			}
			.mini_galerie {
				display: flex;
				flex-wrap: wrap;
				align-items: stretch;
				margin-top: 30px;
				legend {
					flex: 0 0 100%;
					padding: 10px 0;
					font-style: italic;
					color: lighten(black, 60%);
					font-size: 13px;
				}
				.picture_1,
				.picture_2 {
					flex: 0 0 25%;
					padding-right: 20px;
					.fix {
						padding-bottom: 100%;
						display: block;
						width: 100%;
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						background-color: lighten(black, 96%);
					}
				}
				.picture_3 {
					flex: 0 0 50%;
					.fix {
						height: 100%;
						display: block;
						width: 100%;
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						background-color: lighten(black, 96%);
					}
				}
			}
		}
	}
	.your-advantages {
		text-align: center;
		left: 0;
		right: 0;
		bottom: 80px;
		position: absolute;
		img {
			max-width: 100%;
			height: auto;
		}
	}
}
</style>
