<template>
	<div id="page12" ref="page12">
		<div class="layout">
			<div class="visuel_pleine_page" :style="'background-image: url(' + page12_img + ')'">
				<div class="form" name="image_form" id="image_form" @submit.prevent="">
					<input type="file" name="page12_image" id="page12_image" ref="page12_image" @change="handleFile" />
					<label for="page12_image"
						>Click here <br />to select a new picture <br /><em>Format JPEG<br />Taille : 1090px * 1513px</em><i class="fad fa-image-polaroid"></i
					></label>
					<button type="submit">Submit</button>
				</div>
			</div>
			<div class="logo-pied-de-page">
				<img src="../../assets/logofooter.png" />
			</div>
			<PiedDePage :numero="$store.state.ordre_des_pages.page12.count" />
		</div>
	</div>
</template>
<script>
import moment from "moment";
import Airtable from "airtable";
import Axios from "axios";
var base = new Airtable({ apiKey: "patbf88vmcP5sIIit.ee71112a4ec13dfd6d6e7072aedf4fe93d4a1a67c5f56495c75a4c178ef61a90" }).base("appYI0r0gqwXitAoC");

export default {
	name: "page12",
	components: {},
	data() {
		return {
			page12_new: "",
		};
	},
	methods: {
		changePage12Image(val) {
			this.page12_new = val;
			this.$store.commit("setActiveElement", {
				field: "image_page_vierge",
				value: val,
			});
		},

		handleFile(e) {
			this.$store.commit("setFreeze", {
				affichage: true,
				texte: "Uploading your picture.<br /><b>Please wait.</b>",
			});
			const data = new FormData();
			data.append("service", "addmedia");
			data.append("file", e.target.files[0]);
			Axios.post("https://agents.easyflat-1892.com/api/custom_service/", data).then((res) => {
				var url = {
					url: res.data.result.lien,
				};
				base("offres").update(
					[
						{
							id: this.$route.params.id,
							fields: {
								image_page_vierge: [
									{
										url: res.data.result.lien,
									},
								],
							},
						},
					],
					(err, records) => {
						if (err) {
							console.log(err);
							return;
						}

						records.forEach((record) => {
							this.$store.commit("setFreeze", {
								affichage: true,
								texte: "Preparing your picture.<br /><b>Please wait.</b>",
							});
							setTimeout(() => {
								base("offres").find(record.id, (err, record2) => {
									if (err) {
										console.error(err);
										return;
									}
									this.changePage12Image(record2.fields.image_page_vierge[0].url);
									this.$store.commit("setFreeze", {
										affichage: false,
										texte: "",
									});
								});
							}, 8000);
						});
					}
				);
			});
		},
	},
	computed: {
		page12_img: {
			get() {
				if (this.page12_new != "") {
					return this.page12_new;
				} else if (this.$store.state.offre_active.image_page_vierge) {
					return this.$store.state.offre_active.image_page_vierge[0].url;
				} else {
					return "";
				}
			},
			set(newVal) {
				this.page12_new = newVal;
			},
		},
	},
	mounted() {},
};
</script>

<style lang="less">
#page12 .layout {
	display: flex;
	height: 100%;
	word-break: break-word;
	.visuel_pleine_page {
		background-position: center;
		background-size: contain;
		display: block;
		width: 100%;
		height: calc(~"100% - 70px");
		background-repeat: no-repeat;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0px;
			background: fadeOut(black, 50%);
			backdrop-filter: blur(10px);
			z-index: 2;
			display: none;
		}
		.form {
			position: absolute;
			z-index: 10;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: white;
			display: none;
			flex-direction: column;
			text-align: center;
			button {
				display: none;
			}
			input[type="file"] {
				visibility: hidden;
			}
			label {
				font-size: 14px;
				font-weight: bold;
				cursor: pointer;
				em {
					display: block;
					font-weight: normal;
					text-transform: none;
					font-size: 12px;
					margin: 4px 0;
				}
				i {
					font-size: 100px;
					display: block;
					margin-top: 15px;
				}
			}
		}
		&:hover {
			&:before {
				display: block;
				animation: appearFromLeft 0.2s ease-out backwards;
			}
			.form {
				display: flex;
				animation: fadeIn 0.3s ease-out backwards;
				animation-delay: 0.3s;
			}
		}
	}
}
</style>
