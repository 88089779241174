<template>
	<div :class="`${item.masked ? 'masked puceor' : 'puceor'}`" ref="puceor" :data-value="item.prix">
		<div class="photo">
			<div class="zone" v-if="item.fields.photo">
				<div class="fix" :style="'background-image: url(' + item.fields.photo[0].url + ')'"></div>
			</div>
			<div class="zone" v-else>
				<div class="fix"></div>
			</div>
			<div v-if="item.fields.id_option == 13">
				<span v-if="$store.state.offre_active.langue == 'fr'">ET</span>
				<span v-if="$store.state.offre_active.langue == 'en'">AND</span>
				<span v-if="$store.state.offre_active.langue == 'ro'">ȘI</span>
				<span v-if="$store.state.offre_active.langue == 'us'">AND</span>
				<span v-if="$store.state.offre_active.langue == 'de'">UND</span>
				<span v-if="$store.state.offre_active.langue == 'pl'">LUB</span>
			</div>
			<div v-else>
				<span v-if="$store.state.offre_active.langue == 'fr'">OU</span>
				<span v-if="$store.state.offre_active.langue == 'en'">OR</span>
				<span v-if="$store.state.offre_active.langue == 'us'">OR</span>
				<span v-if="$store.state.offre_active.langue == 'ro'">SAU</span>
				<span v-if="$store.state.offre_active.langue == 'de'">ODER</span>
				<span v-if="$store.state.offre_active.langue == 'pl'">I</span>
			</div>
		</div>
		<span class="content" v-if="$store.state.offre_active.langue == 'fr'" contenteditable="true"
			@blur="handleTextChange">
			{{ item.custom_text ? item.custom_text : item.fields.nom_fr }}
		</span>
		<span class="content" v-if="$store.state.offre_active.langue == 'de'" contenteditable="true"
			@blur="handleTextChange">
			{{ item.custom_text ? item.custom_text : item.fields.nom_de }}
		</span>
		<span class="content" v-if="$store.state.offre_active.langue == 'en'" contenteditable="true"
			@blur="handleTextChange">
			{{ item.custom_text ? item.custom_text : item.fields.nom_en }}
		</span>
		<span class="content" v-if="$store.state.offre_active.langue == 'pl'" contenteditable="true"
			@blur="handleTextChange">
			{{ item.custom_text ? item.custom_text : item.fields.nom_pl }}
		</span>
		<span class="content" v-if="$store.state.offre_active.langue == 'us'" contenteditable="true"
			@blur="handleTextChange">
			{{ item.custom_text ? item.custom_text : item.fields.nom_us }}
		</span>
		<span class="content" v-if="$store.state.offre_active.langue == 'ro'" contenteditable="true"
			@blur="handleTextChange">
			{{ item.custom_text ? item.custom_text : item.fields.nom_ro }}
		</span>
		<span class="price" v-if="!item.modifiable">
			<money-format :value="item.prix * taux_de_change" :currency-code="$store.state.offre_active.devise"
				:subunits-value="false" :hide-subunits="false" :locale="$store.state.offre_active.langue">
			</money-format>
			<!-- {{ item.prix }} € -->
		</span>
		<span class="activate">
			<i v-if="item.masked" class="fal fa-eye" @click="handleAction"></i>
			<i v-else class="fal fa-eye-slash" @click="handleAction"></i>
		</span>
	</div>
</template>
<script>
import MoneyFormat from "vue-money-format";

export default {
	name: "PuceOr",
	props: ["item"],
	components: { MoneyFormat },
	data() {
		return {
			active: false,
			taux_de_change: 1,
		};
	},
	methods: {
		handleTextChange(e) {
			this.$emit('update-commande-custom-text', {
				id: this.item.id,
				value: e.target.innerText,
			});
		},
		handleAction() {
			this.$emit("control_option_selected", {
				id: this.item.id,
				value: !this.item.masked
			})
		},
	},
	mounted() {
		if (this.$store.state.offre_active.devise != "EUR") {
			if (this.$store.state.offre_active.taux_de_change_perso) {
				this.taux_de_change = this.$store.state.offre_active.taux_de_change_perso;
			} else {
				this.taux_de_change = this.$store.state.currencies[this.$store.state.offre_active.devise];
			}
		}
	},
};
</script>

<style lang="less">
.puceor {
	display: flex;
	padding: 5px 0;
	transition: all 0.3s ease;
	cursor: pointer;
	position: relative;
	&.masked {
		span,
		input {
			color: lighten(black, 80%) !important;
		}
	}
	.activate {
		display: none;
		position: absolute;
		right: 0px;
		top: 0px;
		padding: 10px;
	}
	&:hover {
		.price {
			padding-right: 40px !important;
		}
		.activate {
			display: block;
			
		}
	}
	&.active {
		opacity: 1;
		span,
		input {
			color: lighten(black, 0%) !important;
		}
		.content {
			font-weight: bold;
		}
		.photo {
			span {
				display: none;
			}
		}
	}
	.photo {
		flex: 0 0 auto;
		width: 100px;
		text-align: center;
		color: #312483;
		font-weight: bold;
		.zone {
			position: relative;
			margin-bottom: 20px;
			.fix {
				padding-bottom: 100%;
				display: block;
				background-color: lighten(black, 95%);
				background-position: center;
				background-size: cover;
			}
		}
	}
	b {
		color: #312483;
		font-weight: bold;
		flex: 0 0 auto;
		width: 40px;
	}
	.content {
		flex: 1;
		text-align: left;
		padding: 0 20px;
		font-size: 16px;
		line-height: 22px;
	}
	span.price {
		flex: 0 0 auto;
		width: 110px;
		text-align: right;
		text-transform: uppercase;
		font-size: 0.9em;
		transition: all .3s ease;
		font-weight: bold;
		color: #312483;
		.money_format {
			white-space: nowrap;
		}
		input[type="text"] {
			border: none;
			appearance: none;
			width: 50px;
			color: #312483;
			font-size: 0.9em;
			font-weight: bold;
			text-align: right;
			border-bottom: solid 1px fadeOut(black, 90%);
		}
	}
	span.included {
		text-align: right;
		color: #312483;
		text-transform: uppercase;
		font-size: 0.9em;
		font-weight: bold;
	}
}
.vue-tooltip-theme {
	background: fadeOut(black, 10%);
	font-size: 12px;
	padding: 5px;
	color: white;
	font-weight: 500;
}
</style>
