<template>
	<div id="page10" ref="page10">
		<div class="layout">
			<div class="gauche"></div>
			<div class="droite">
				<h1>
					<span>3/</span>
					{{ $store.state.textes.page10.sales_conditions_title }}
				</h1>
				<h2
					v-if="$store.state.offre_active.page2_intitule && $store.state.offre_active.page2_intitule != '-'"
					v-html="$store.state.offre_active.page2_intitule"
				/>
				<h2 v-else>
					<span v-if="$store.state.offre_active.langue == 'fr'">{{ $store.state.offre_active.machine.display_name_fr }}</span>
					<span v-if="$store.state.offre_active.langue == 'en'">{{ $store.state.offre_active.machine.display_name_en }}</span>
					<span v-if="$store.state.offre_active.langue == 'us'">{{ $store.state.offre_active.machine.display_name_us }}</span>
					<span v-if="$store.state.offre_active.langue == 'ro'">{{ $store.state.offre_active.machine.display_name_ro }}</span>
					<span v-if="$store.state.offre_active.langue == 'de'">{{ $store.state.offre_active.machine.display_name_de }}</span>
					<span v-if="$store.state.offre_active.langue == 'pl'">{{ $store.state.offre_active.machine.display_name_pl }}</span>
					{{ $store.state.offre_active.machine.name }}
				</h2>

				<div
					class="block first"
					@blur="handleChange"
					v-if="$store.state.offre_active.page10_chapitre1"
					contenteditable="true"
					data-field="page10_chapitre1"
					v-html="$store.state.offre_active.page10_chapitre1"
				/>
				<div @blur="handleChange" v-else class="block first" contenteditable="true" data-field="page10_chapitre1">
					<h3>
						<span>{{ $store.state.textes.page10.price_conditions_title }}</span>
					</h3>
					<div v-if="$store.state.offre_active.langue == 'fr'" v-html="$store.state.cgv[0].version_fr"></div>
					<div v-if="$store.state.offre_active.langue == 'de'" v-html="$store.state.cgv[0].version_de"></div>
					<div v-if="$store.state.offre_active.langue == 'en'" v-html="$store.state.cgv[0].version_en"></div>
					<div v-if="$store.state.offre_active.langue == 'us'" v-html="$store.state.cgv[0].version_us"></div>
					<div v-if="$store.state.offre_active.langue == 'ro'" v-html="$store.state.cgv[0].version_ro"></div>
					<div v-if="$store.state.offre_active.langue == 'pl'" v-html="$store.state.cgv[0].version_pl"></div>

					<div class="taux_de_change" v-if="$store.state.offre_active.devise != 'EUR'">
						{{ $store.state.textes.page10.taux_de_change }} {{ $store.state.offre_active.date_modifiee | moment("MMMM Do, Y") }} : 1 EUR
						= {{ $store.state.currencies[$store.state.offre_active.devise] }}
						{{ $store.state.offre_active.devise }}
					</div>
				</div>

				<div
					class="block"
					@blur="handleChange"
					v-if="$store.state.offre_active.page10_chapitre2"
					contenteditable="true"
					data-field="page10_chapitre2"
					v-html="$store.state.offre_active.page10_chapitre2"
				/>
				<div @blur="handleChange" v-else class="block" contenteditable="true" data-field="page10_chapitre2">
					<h3>
						<span>{{ $store.state.textes.page10.payment_conditions_title }}</span>
					</h3>
					<div v-if="$store.state.offre_active.langue == 'fr'" v-html="$store.state.cgv[1].version_fr"></div>
					<div v-if="$store.state.offre_active.langue == 'de'" v-html="$store.state.cgv[1].version_de"></div>
					<div v-if="$store.state.offre_active.langue == 'en'" v-html="$store.state.cgv[1].version_en"></div>
					<div v-if="$store.state.offre_active.langue == 'us'" v-html="$store.state.cgv[1].version_us"></div>
					<div v-if="$store.state.offre_active.langue == 'ro'" v-html="$store.state.cgv[1].version_ro"></div>
					<div v-if="$store.state.offre_active.langue == 'pl'" v-html="$store.state.cgv[1].version_pl"></div>
				</div>

				<div
					class="block"
					@blur="handleChange"
					v-if="$store.state.offre_active.page10_chapitre3"
					contenteditable="true"
					data-field="page10_chapitre3"
					v-html="$store.state.offre_active.page10_chapitre3"
				/>
				<div @blur="handleChange" v-else class="block" contenteditable="true" data-field="page10_chapitre3">
					<h3>
						<span>{{ $store.state.textes.page10.startup_title }}</span>
					</h3>
					<div v-if="$store.state.offre_active.langue == 'fr'" v-html="$store.state.cgv[2].version_fr"></div>
					<div v-if="$store.state.offre_active.langue == 'de'" v-html="$store.state.cgv[2].version_de"></div>
					<div v-if="$store.state.offre_active.langue == 'en'" v-html="$store.state.cgv[2].version_en"></div>
					<div v-if="$store.state.offre_active.langue == 'us'" v-html="$store.state.cgv[2].version_us"></div>
					<div v-if="$store.state.offre_active.langue == 'ro'" v-html="$store.state.cgv[2].version_ro"></div>
					<div v-if="$store.state.offre_active.langue == 'pl'" v-html="$store.state.cgv[2].version_pl"></div>
				</div>

				<div
					class="block"
					@blur="handleChange"
					v-if="$store.state.offre_active.page10_chapitre4"
					contenteditable="true"
					data-field="page10_chapitre4"
					v-html="$store.state.offre_active.page10_chapitre4"
				/>
				<div @blur="handleChange" v-else class="block" contenteditable="true" data-field="page10_chapitre4">
					<h3>
						<span>{{ $store.state.textes.page10.garantee_title }}</span>
					</h3>
					<div v-if="$store.state.offre_active.langue == 'fr'" v-html="$store.state.cgv[3].version_fr"></div>
					<div v-if="$store.state.offre_active.langue == 'de'" v-html="$store.state.cgv[3].version_de"></div>
					<div v-if="$store.state.offre_active.langue == 'en'" v-html="$store.state.cgv[3].version_en"></div>
					<div v-if="$store.state.offre_active.langue == 'us'" v-html="$store.state.cgv[3].version_us"></div>
					<div v-if="$store.state.offre_active.langue == 'ro'" v-html="$store.state.cgv[3].version_ro"></div>
					<div v-if="$store.state.offre_active.langue == 'pl'" v-html="$store.state.cgv[3].version_pl"></div>
				</div>

				<div
					class="block"
					@blur="handleChange"
					v-if="$store.state.offre_active.page10_chapitre5"
					contenteditable="true"
					data-field="page10_chapitre5"
					v-html="$store.state.offre_active.page10_chapitre5"
				/>
				<div @blur="handleChange" v-else class="block" contenteditable="true" data-field="page10_chapitre5">
					<h3>
						<span>{{ $store.state.textes.page10.validity_title }}</span>
					</h3>
					<div v-if="$store.state.offre_active.langue == 'fr'" v-html="$store.state.cgv[4].version_fr"></div>
					<div v-if="$store.state.offre_active.langue == 'de'" v-html="$store.state.cgv[4].version_de"></div>
					<div v-if="$store.state.offre_active.langue == 'en'" v-html="$store.state.cgv[4].version_en"></div>
					<div v-if="$store.state.offre_active.langue == 'us'" v-html="$store.state.cgv[4].version_us"></div>
					<div v-if="$store.state.offre_active.langue == 'ro'" v-html="$store.state.cgv[4].version_ro"></div>
					<div v-if="$store.state.offre_active.langue == 'pl'" v-html="$store.state.cgv[4].version_pl"></div>
				</div>

				<div
					class="block"
					@blur="handleChange"
					v-if="$store.state.offre_active.page10_chapitre6"
					contenteditable="true"
					data-field="page10_chapitre6"
					v-html="$store.state.offre_active.page10_chapitre6"
				/>
				<div @blur="handleChange" v-else class="block" contenteditable="true" data-field="page10_chapitre6">
					<h3>
						<span>{{ $store.state.textes.page10.delivery_title }}</span>
					</h3>
					<div v-if="$store.state.offre_active.langue == 'fr'" v-html="$store.state.cgv[5].version_fr"></div>
					<div v-if="$store.state.offre_active.langue == 'de'" v-html="$store.state.cgv[5].version_de"></div>
					<div v-if="$store.state.offre_active.langue == 'en'" v-html="$store.state.cgv[5].version_en"></div>
					<div v-if="$store.state.offre_active.langue == 'us'" v-html="$store.state.cgv[5].version_us"></div>
					<div v-if="$store.state.offre_active.langue == 'ro'" v-html="$store.state.cgv[5].version_ro"></div>
					<div v-if="$store.state.offre_active.langue == 'pl'" v-html="$store.state.cgv[5].version_pl"></div>
				</div>

				<div
					class="block"
					@blur="handleChange"
					v-if="$store.state.offre_active.page10_chapitre7"
					contenteditable="true"
					data-field="page10_chapitre7"
					v-html="$store.state.offre_active.page10_chapitre7"
				/>
				<div @blur="handleChange" v-else class="block" contenteditable="true" data-field="page10_chapitre7">
					<h3>
						<span>{{ $store.state.textes.page10.training_title }}</span>
					</h3>
					<div v-if="$store.state.offre_active.langue == 'fr'" v-html="$store.state.cgv[6].version_fr"></div>
					<div v-if="$store.state.offre_active.langue == 'de'" v-html="$store.state.cgv[6].version_de"></div>
					<div v-if="$store.state.offre_active.langue == 'en'" v-html="$store.state.cgv[6].version_en"></div>
					<div v-if="$store.state.offre_active.langue == 'us'" v-html="$store.state.cgv[6].version_us"></div>
					<div v-if="$store.state.offre_active.langue == 'ro'" v-html="$store.state.cgv[6].version_ro"></div>
					<div v-if="$store.state.offre_active.langue == 'pl'" v-html="$store.state.cgv[6].version_pl"></div>
				</div>
			</div>
			<div class="logo-pied-de-page">
				<img src="../../assets/logofooter.png" />
			</div>
			<PiedDePage :numero="$store.state.ordre_des_pages.page10.count" />
		</div>
	</div>
</template>
<script>
import moment from "moment";
import Airtable from "airtable";
const base = new Airtable({ apiKey: "patbf88vmcP5sIIit.ee71112a4ec13dfd6d6e7072aedf4fe93d4a1a67c5f56495c75a4c178ef61a90" }).base("appYI0r0gqwXitAoC");

export default {
	name: "page10",
	components: {},
	data() {
		return {};
	},
	methods: {
		handleChange(e) {
			if (this.$store.state.offre_active.statut != "draft") {
				alert("Sorry, you can't modify an issued offer.");
			} else {
				this.$store.commit("setFreeze", {
					affichage: true,
					texte: "Saving your data.<br /><b>Please Wait.</b>",
				});
				this.$store.state.offre_active[e.currentTarget.dataset.field] = e.currentTarget.innerHTML;
				let fields = {
					[e.currentTarget.dataset.field]: e.currentTarget.innerHTML,
				};
				base("offres").update(
					[
						{
							id: this.$store.state.offre_active.id,
							fields: fields,
						},
					],
					(err, records) => {
						this.$store.commit("setFreeze", {
							affichage: false,
							texte: "",
						});
					}
				);
			}
		},
	},
	mounted() {},
};
</script>

<style lang="less">
#page10 .layout {
	display: flex;
	.gauche {
		flex: 0 0 300px;
	}
	.droite {
		flex: 1;
		padding-left: 80px;
		.taux_de_change {
			font-size: 0.9em;
			font-style: italic;
			font-weight: 300;
			margin-top: 10px;
		}
		h1 {
			color: #312483;
			text-transform: uppercase;
			font-size: 30px;
			line-height: 34px;
			font-weight: bold;
			color: #312483;
			margin-bottom: 0px;
			span {
				color: black;
			}
		}
		h2 {
			color: lighten(black, 60%);
			text-transform: uppercase;
			font-size: 30px;
			line-height: 34px;
			font-weight: bold;
			margin-bottom: 0px;
			margin-top: 0;
		}
		.block {
			margin-bottom: 50px;
			line-height: 1.5;
			font-size: 18px;
			li {
				list-style: none;
			}
			h3 {
				position: relative;
				margin: 0 0 10px;
				&:after {
					content: ">";
					color: #312483;
					display: inline-block;
					margin-right: 5px;
					font-weight: bold;
					position: absolute;
					left: -20px;
				}
				&:before {
					left: 0;
					right: 0;
					top: 0px;
					height: 2px;
					background: #312483;
					content: "";
					position: absolute;
				}
				span {
					background: white;
					color: #312483;
					text-transform: uppercase;
					font-weight: bold;
					padding-right: 10px;
					font-size: 20px;
					position: relative;
				}
			}
			&.first {
				margin-top: 120px;
			}
		}
	}
}
</style>
